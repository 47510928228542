import { mapState } from 'vuex';
import { env } from '~/env';

export default {
  computed: {
    ...mapState('layout', {
      globalSitename: state => state.story?.content?.meta_sitename,
      globalDescription: state => state.story?.content?.meta_description,
      globalKeywords: state => state.story?.content?.meta_keywords,
      globalImage: state => state.story?.content?.meta_image?.filename,
    }),
    pageTitle() {
      const reg = /<wbr>/g;
      const title = this.story?.content?.title?.replace(reg, '');
      const pageName = this.story?.name;

      return title || pageName;
    },
    metaUrl() {
      return env.baseUrl + this.$route.path;
    },
    metaTitle() {
      const overwrite = this.story?.content?.meta_title;
      if (overwrite) return overwrite;

      return this.isHome ? this.globalSitename : `${this.pageTitle} | ${this.globalSitename}`;
    },
    metaDescription() {
      return this.story?.content?.meta_description || this.globalDescription;
    },
    metaKeywords() {
      return this.story?.content?.meta_keywords || this.globalKeywords;
    },
    metaImage() {
      return this.story?.content?.meta_image?.filename || this.globalImage;
    },
  },
  methods: {
    sbImageSize(src, side, fallback) {
      return this.$options.filters.sbImageSize(src, side, fallback);
    },
  },
  head() {
    let head = {
      meta: [
        { hid: 'og:url', property: 'og:url', content: this.metaUrl },
      ],
      link: [],
    };

    if (this.metaTitle) {
      head.title = this.metaTitle;
      head.meta.push(
        { hid: 'title', name: 'title', content: this.metaTitle },
        { hid: 'og:title', property: 'og:title', content: this.metaTitle },
        { hid: 'twitter:title', property: 'twitter:title', content: this.metaTitle },
      );
    }

    if (this.metaDescription) {
      head.meta.push(
        { hid: 'description', name: 'description', content: this.metaDescription },
        { hid: 'og:description', property: 'og:description', content: this.metaDescription },
        { hid: 'twitter:description', property: 'twitter:description', content: this.metaDescription },
      );
    }

    if (this.metaKeywords) {
      head.meta.push(
        { hid: 'keywords', name: 'keywords', content: this.metaKeywords },
      );
    }

    if (this.metaImage) {
      const width = this.sbImageSize(this.metaImage, 'width');
      const height = this.sbImageSize(this.metaImage, 'height');

      head.meta.push(
        { hid: 'og:image', property: 'og:image', content: this.metaImage },
        { hid: 'twitter:image', property: 'twitter:image', content: this.metaImage },
      );

      head.link.push(
        { hid: 'image_src', rel: 'image_src', href: this.metaImage },
      );

      if (width && height) {
        head.meta.push(
          { hid: 'og:image:width', property: 'og:image:width', content: width },
          { hid: 'og:image:height', property: 'og:image:height', content: height },
        );
      }
    }

    if (this.globalSitename) {
      head.meta.push(
        { hid: 'apple-mobile-web-app-title', name: 'apple-mobile-web-app-title', content: this.globalSitename },
        { hid: 'application-name', name: 'application-name', content: this.globalSitename },
        { hid: 'og:site_name', property: 'og:site_name', content: this.globalSitename },
      );
    }

    return head;
  },
};
